/* define font size to be relative to screen height*/
html {
  font-size: calc(10px + 0.390625vh);
}

:root {
  /* Default to 1vw when height is greater relative to the width */
  --vss: 1vw;
}

@media (min-aspect-ratio: 178/100) {
  :root {
      --vss: 1.778vh;
  }
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* #preload-images {
  display: none;
}

#preload-images::after {
  content: url('./images/frame_0.png') url('./images/frame_1.png') url('./images/frame_2.png') url('./images/frame_3.png') url('./images/frame_4.png') url('./images/frame_5.png') url('./images/frame_6.png') url('./images/frame_7.png') url('./images/frame_8.png') url('./images/frame_9.png') url('./images/frame_10.png') url('./images/frame_11.png') url('./images/frame_12.png') url('./images/frame_13.png') url('./images/frame_14.png') url('./images/frame_15.png') url('./images/frame_16.png') url('./images/frame_17.png') url('./images/frame_18.png') url('./images/frame_19.png') url('./images/frame_20.png') url('./images/frame_21.png') url('./images/frame_22.png') url('./images/frame_23.png');
} */


body {
  background-image: url('./images/frame_8.png');
  background-repeat: repeat;
}

body::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7); /* Adjust the opacity as needed */
  z-index: -1;
}

.game {
  background-image: none !important;
}

.game::before {
  background: rgba(0, 0, 0, 0.8);
}

.botc {
  background-image: none !important;
  touch-action: none;
  overscroll-behavior: contain;
}

.botc::before {
  background: white;
}

@media (max-width: 600px) {
  body {
    background-image: url('./images/frame_8_small.png');
  }
}
/* 
body {
  animation: frameAnimation 8s infinite;
  background-repeat: repeat;
}

@keyframes frameAnimation {
  0% { background-image: url('/images/frame_0.png'); }
  4.17% { background-image: url('/images/frame_1.png'); }
  8.33% { background-image: url('/images/frame_2.png'); }
  12.5% { background-image: url('/images/frame_3.png'); }
  16.77% { background-image: url('/images/frame_4.png'); }
  20.83% { background-image: url('/images/frame_5.png'); }
  25% { background-image: url('/images/frame_6.png'); }
  29.17% { background-image: url('/images/frame_7.png'); }
  33.33% { background-image: url('/images/frame_8.png'); }
  37.5% { background-image: url('/images/frame_9.png'); }
  41.67% { background-image: url('/images/frame_10.png'); }
  45.83% { background-image: url('/images/frame_11.png'); }
  50% { background-image: url('/images/frame_12.png'); }
  54.17% { background-image: url('/images/frame_13.png'); }
  58.33% { background-image: url('/images/frame_14.png'); }
  62.5% { background-image: url('/images/frame_15.png'); }
  66.67% { background-image: url('/images/frame_16.png'); }
  70.83% { background-image: url('/images/frame_17.png'); }
  75% { background-image: url('/images/frame_18.png'); }
  79.17% { background-image: url('/images/frame_19.png'); }
  83.33% { background-image: url('/images/frame_20.png'); }
  87.5% { background-image: url('/images/frame_21.png'); }
  91.67% { background-image: url('/images/frame_22.png'); }
  95.83% { background-image: url('/images/frame_23.png'); }
  100% { background-image: url('/images/frame_0.png'); }
} */
